@font-face {
    font-family: 'Rijksoverheid Sans';
    src: url("./fonts/rijksoverheidsanswebtext-regular-webfont.woff2") format('woff2'),
         url("./fonts/rijksoverheidsanswebtext-regular-webfont.woff") format('woff'),
         url("./fonts/rijksoverheidsanswebtext-regular-webfont.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: 'Rijksoverheid Sans';
    src: url("./fonts/rijksoverheidsanswebtext-bold-webfont.woff2") format('woff2'),
         url("./fonts/rijksoverheidsanswebtext-bold-webfont.woff") format('woff'),
         url("./fonts/rijksoverheidsanswebtext-bold-webfont.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: 'Rijksoverheid Sans';
    src: url("./fonts/rijksoverheidsanswebtext-italic-webfont.woff2") format('woff2'),
         url("./fonts/rijksoverheidsanswebtext-italic-webfont.woff") format('woff'),
         url("./fonts/rijksoverheidsanswebtext-italic-webfont.ttf") format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: fallback;
}

.form-floating>textarea.form-control.textarea-100px {
    height: 100px;
    min-height: 64px;
}

/* Bootstrap overrides */
.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: rgb(248, 249, 251);
}
.table {
    --bs-table-bg: white
}

.nav-link.active, .nav-item.dropdown.active {
    /* font-weight: bold; */
    color: black;
    background-color: #eee;
    border-radius: 0.25em;
}
.nav-item.dropdown.active > a {
    color: black;
}
.nav-item.dropdown.active > a svg path {
    fill: black!important;
}
@media (max-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 100%!important;
    }
}
.breadcrumb-item + .breadcrumb-item::before {
    content: "›";
}
@media reader, speech, aural {
    .breadcrumb-item + .breadcrumb-item::before {
        display: none;
        visibility: hidden;
    }
}
.breadcrumb-item.active {
    font-weight: bold;
}
.breadcrumb-item {
    font-size: 90%;
}

nav.navBreadcrumb {
    padding: 1em;
    margin-bottom: 1.5em;
}

.breadcrumb {
    margin-bottom: 0;
}

input:disabled, textarea:disabled, input[type=text]:disabled + label, textarea:disabled + label {
    color:grey;
}
button.iconButton.btn.btn-outline-primary .icon svg path {
    fill: var(--bs-btn-hover-bg)!important;
}
button.iconButton.btn.btn-outline-secondary .icon svg path {
    fill: var(--bs-btn-hover-bg)!important;
}
button.iconButton.btn.btn-outline-success .icon svg path {
    fill: var(--bs-btn-hover-bg)!important;
}
button.iconButton.btn.btn:hover .icon svg path,
    button.iconButton.btn.btn:focus .icon svg path {
    fill: white!important;
}

.btn-close {
    background-image: url(./css-images/bootstrap-close.svg);
}
.form-select {
    background-image: url(./css-images/bootstrap-select.svg);
}
.form-check-input:checked[type=radio] {
    background-image: url(./css-images/bootstrap-radio-checked.svg);
}
.form-check-input:checked[type=checkbox] {
    background-image: url(./css-images/bootstrap-checkbox-checked.svg);
}
.form-switch .form-check-input {
    background-image: url(./css-images/bootstrap-switch-unchecked.svg);
}
.form-switch .form-check-input:checked {
    background-image: url(./css-images/bootstrap-switch-checked.svg)!important;
}
.form-switch .form-check-input:focus {
    background-image: url(./css-images/bootstrap-switch-focus-unchecked.svg);
}
.navbar-toggler-icon {
    background-image: url(./css-images/bootstrap-navbar-toggler.svg);
}
.input-group .input-group-text {
    width: 8em;
}
/* End bootstrap overrides */

h1, .h1 {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
}
h1 svg {
    margin-right: 0.1em;
}
  
html {
    padding: 0;
    margin: 0;
    height: 100%;
}
body {
    font-family: "Rijksoverheid Sans", sans-serif;
    background-color: rgb(245, 247, 251);
    height: 100%;
    padding-top: 1em;
}

#root {
    position: relative;
    min-height: 100%;
}

a:hover {
    text-decoration: none;
}

header nav {
    padding-left: 1em;
    padding-right: 1em;
    background-color: white;
    padding-bottom: 0.5em;
    margin-bottom: 2em;
    box-shadow: 0.05em 0.05em 0.25em lightgrey;
}
header nav.navbar {
    padding-top: 0;
}
header nav .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
}
header nav .navbar-nav {
    align-items: center;
}
header nav .navbar-collapse .navbar-nav > a {
    margin-left: 1em;
    margin-right: 1em;
}

header nav .navbar-collapse.show .navbar-nav > a, header nav .navbar-collapse.collapsing .navbar-nav > a {
    margin-left: 0;
    margin-right: 0;
}

header nav .collapse {
    padding-top: 0.5em;
}

header #nav-account-dropdown {
    display: flex;
    align-items: center;
}
header #nav-account-dropdown.nav-link {
    padding: 0;
}
header #nav-account-dropdown > div {
    margin-left: 0.5em;
}
header .nav-link, header .dropdown-item {
    display: flex;
    align-items: center;
}
header .nav-link svg, header .dropdown-item svg {
    margin-right: 0.25em;
}
header .nav-link svg path {
    fill: var(--bs-nav-link-color)!important;
}
header .nav-link.active svg path {
    fill: var(--bs-nav-link-hover-color)!important;
}
header .nav-link:focus svg path, .nav-link:hover svg path {
    fill: var(--bs-nav-link-hover-color)!important;
}

.avatar {
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
    color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    cursor: default;
    user-select: none;
    text-shadow: 1px 1px #444;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
header .userIcon {
    display: none;
}
header .avatar {
    width: 48px;
    height: 48px;
    margin-left: 1em;
}
header .avatar .initials {
    font-size: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
}
.profile .avatar {
    width: 8rem;
    height: 8rem;
    font-size: 4em;
}

main.page {
    padding-bottom: 6em;
}
main.page p {
    line-height: 1.75em;
}

footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 5;
    background-color: white;
    margin-top: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    box-shadow: 0.05em 0.05em 0.25em lightgrey;
    font-size: 0.75em;
}
footer .environment {
    color: lightgrey;
}
footer nav.navbar {
    padding: 0;
}
footer .nav-link.active {
    background-color: transparent;
    font-weight: bold;
    cursor: default;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: bold;
}

.area {
    width: 100%;
    background-color: white;
    padding: 2em;
    border-radius: 0.25em;
    box-shadow: 0.05em 0.05em 0.25em lightgrey;
}

.genericScreenBackButton {
    display: flex;
    justify-content: flex-end;
}
.genericScreenBackButton > button {
    margin-bottom: 1em;
}

.area h2 {
    display: flex;
    align-items: center;
    font-size: 1.25em;
    font-weight: bold;
    color: #504e58;
    margin-bottom: 1em;
}

.area h3 {
    font-size: 1.1em;
    font-weight: bold;
    color: #504e58;
    margin: 0;
    margin-top: 1.5em;
}

.infoBox {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.infoBox .header {
    display: flex;
    justify-content: space-between;
}
.infoBox .header .icon {
    display: flex;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(160, 230, 195);
    color: white;
    width: min-content;
    height: min-content;
    padding: 0.5em;
}

.infoBox .number {
    font-size: 2em;
    letter-spacing: -1px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.infoBox .number .spinner-border {
    font-size:1rem;
}

.infoBox .link a {
    color: rgb(2, 104, 155);
    text-decoration: none;

}
.infoBox .link a:hover {
    text-decoration: underline;
}

.dropdown-menu {
    box-shadow: 0.05em 0.05em 0.25em lightgrey;
}

.infoBox .filesList .name, .infoBox .filesList .description {
    margin-bottom: 0.5em;
    word-break: break-word;
}

#uploadFilenames {
    min-height: 5em;
    max-height: 20em;
}
#inputFileUpload {
    display: none;
}
.copyCommand {
    font-family: Monaco, "Courier New", Courier, monospace;
    font-size: 12px;
    background-color: #f6f6ff;
    cursor: pointer;
}
textarea.copyCommand {
    min-height: 4em;
    max-height: 20em;
}
.createUploadInput {
    font-family: Monaco, "Courier New", Courier, monospace;
    font-size: 12px;
    max-height: 150px;
    overflow-y: scroll;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    white-space: pre-wrap;
}
.createUploadInput.dragActive {
    border-color: #198754;  /* Bootstrap button success background color */
    border-style: dashed;
    background-color: #f0fff0;
}

.filterBar {
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0.05em 0.05em 0.25em lightgrey;
    border-radius: 0.25em;
    padding: 1em;
    margin-bottom: 1em;
    font-size: 90%;
}
.filterBar h2 {
    font-size: 100%;
    font-weight: bold;
    margin: 0;
}

.filterBar .form-check {
    white-space: nowrap;
}

.resultTableHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
}
.resultTableHeader > div:not(:last-child) {
    margin-right: 0.5em;
}
.resultTableHeader button {
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 599px) {
    .resultTableHeader .iconButton .icon {
        margin-right: 0;
    }
    .resultTableHeader .iconButton .caption {
        display: none;
    }
}

.search-query-clear-input:hover svg path,
    .search-query-clear-input:focus svg path {
    fill: white!important;
}

.resultTable tr td:last-of-type {
    /* Fix Bootstrap double width border */
    border-left-width: 0;
}

.resultTable.busy {
    opacity: 0.5;
    transition-delay: 50ms;
}
.resultTable table {
    table-layout: fixed;
    font-size: 80%;
    box-shadow: 0.05em 0.05em 0.25em lightgrey;
    /* font-size: 0.75rem; */   /* Default */
    font-size: 0.9rem;  /* Rijksfont */
    letter-spacing: -0.25px;
}
.resultTable table th {
    color: #666;
    user-select: none;
    white-space: nowrap;
}
.resultTable th .columnHeader {
    width: 100%;
    display: flex;
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
    text-align: start;
}
.resultTable table th .columnHeader .caption {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.resultTable table th:not(.checkboxHeader):not(.actionsHeader) {
    cursor: pointer;
}
.resultTable table td {
    color: #444!important;
}
.resultTable table th.checkboxHeader {
    width: 2.5em;
}
.resultTable table th.actionsHeader.actionsCount-1 {
    width: 3em;
}
.resultTable table th.actionsHeader.actionsCount-2 {
    width: 5em;
}
.resultTable table th.actionsHeader.actionsCount-3 {
    width: 7em;
}
.resultTable table th.actionsHeader.actionsCount-4 {
    width: 9em;
}
.resultTable table tr td.column-actions {
    display: flex;
    justify-content: center;
}

.resultTable table td:not(.column-published) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    letter-spacing: -0.5px;
}

.resultTable table .rowActions {
    display: flex;
}
.resultTable table .rowActions .rowAction {
    margin-right: 0.3em;
}
.resultTable table .rowActions .rowAction .iconButton {
    background-color: rgb(2, 104, 156);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    border-width: 0;
}
.resultTable table .rowActions .rowAction .iconButton .icon {
    margin-right: 0;
    display: flex;
    align-items: center;
}
.resultTable table .rowActions .rowAction.delete .iconButton {
    background-color: rgb(226, 42, 60);
}
.resultTable table .rowActions .rowAction:last-of-type {
    margin-right:0;
}
.resultTable table .rowActions .rowAction button:not(:disabled):hover {
    opacity: 0.75;
}

.btn:disabled {
    opacity: 0.5;
}

.rename-in-progress {
    opacity: 0.6;
}

/* resultTableFooter */
.resultTableFooter .bulkActions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}
.resultTableFooter .bulkActions .caption {
    display: flex;
    align-items: center;
    margin-right: 0.5em;
}
.resultTableFooter .resultPagination {
    display: flex;
    justify-content: center;
    height: 100%;
}
.resultTableFooter .resultPagination .pagination {
    margin: 0;
    user-select: none;
}
.resultTableFooter .resultPageInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}
@media only screen and (max-width: 1199px) {
    .resultTableFooter .bulkActions, .resultTableFooter .resultPageInfo {
        justify-content: center;
    }
}

.detailTable th {
    width: 12vw;
}

.iconButton {
    display: flex;
    justify-content: flex-start;
    width: min-content;
}
.iconButton {
    display: inline-flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.iconButton .icon {
    margin-right: 0.25em;
}
.copyClipboardGroup div:not(:last-child) {
    margin-bottom: 0.5rem;
}
.copyClipboard .iconButton .icon {
    margin-right: 0;
}
.iconButtonSet {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.iconButtonSet .iconButton:not(:last-child) {
    margin-right: 0.5em;
}
.iconButtonSet .iconButton {
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: 0.5em;
}
.iconButtonSet .iconButton .icon {
    margin-right: 0.25em;
    display: flex;
    align-items: center;
}
.iconButtonSet .iconButton .caption {
    /* display: flex;
    align-items: center; */
    text-overflow: ellipsis;
    overflow: hidden;
}
.iconButtonSet .formSpinner {
    display: flex;
    align-items: center;
    margin-left: 0.5em;
}
form hr {
    margin-top: 2em;
    margin-bottom: 1em;
}

.tooltip.formValidation {
    --bs-tooltip-bg: var(--bs-red);
}

.tooltip.copyClipboard {
    --bs-tooltip-bg: var(--bs-blue);
}

.text-align-right {
    text-align: right;
}

.isPublished, .isNotPublished, .numberOfFiles {
    font-size: 80%;
    border-radius: 5px;
    background-color: var(--bs-green);
    width: min-content;
    color: white;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    white-space: nowrap;
    cursor: default;
    letter-spacing: -0.6px;
    user-select: none;
}
.isNotPublished {
    background-color: var(--bs-red);
}
.numberOfFiles {
    background-color: rgb(1, 104, 157);
}
.numberOfFiles .spinner-border {
    width: 0.75rem;
    height: 0.75rem;
    border-width: 0.15em;
}

.verifyUserSpinner {
    position: fixed;
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.loadingSpinner {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
}
.loadingSpinner .container {
    width: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 2em;
}
.loadingSpinner .container .caption {
    margin-top: 0.5em;
    text-align: center;
    white-space: nowrap;
}

@media only screen and (max-width: 1000px) {
    header nav .topBar .menuBarSmall {
        display: block;
    }
    header nav .topBar .menuBar {
        display: none;
    }
}


/*
X-Small	None	<576px
Small	sm	≥576px
Medium	md	≥768px
Large	lg	≥992px
Extra large	xl	≥1200px
Extra extra large	xxl	≥1400px
*/
@media only screen and (max-width: 575px) {
    th.hide-sm, td.hide-sm {
        display: none;
    }
    th.no-width-sm, td.no-width-sm {
        width: initial!important;
    }
    th.width-50p-sm, td.width-50p-sm {
        width: 50%!important;
    }
    th.width-100p-sm, td.width-100p-sm {
        width: 100%!important;
    }
}
@media only screen and (max-width: 767px) {
    th.hide-md, td.hide-md {
        display: none;
    }
}
@media only screen and (max-width: 991px) {
    th.hide-lg, td.hide-lg {
        display: none;
    }
    header nav .collapse {
        padding-top: 0;
    }
    .nav-link, .nav-item.dropdown {
        justify-content: flex-start;
        width: 100%;
    }
    .nav-link {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .nav-link.active, .nav-item.dropdown.active {
        background-color: transparent;
        font-weight: bold;
    }
    header .avatar {
        display: none;
    }
    header .userIcon {
        display: block;
    } 
    header #nav-account-dropdown.nav-link {
        padding: 0.5rem 1rem 0.5rem 1rem;
    } 
    header #nav-account-dropdown > div {
        margin-left: 0em;
    }
    header nav .navbar-nav {
        align-items: center;
        margin-top: 1rem;
    }
}
@media only screen and (max-width: 1199px) {
    th.hide-xl, td.hide-xl {
        display: none;
    }
}
@media only screen and (max-width: 1399px) {
    th.hide-xxl, td.hide-xxl {
        display: none;
    }
}
